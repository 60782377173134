import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import UserIcon from '../assets/icons/user.png';
import { AppIconButton, ErrorBoundary } from '../components';
import { useOnMobile } from '../hooks/layout';
import LogoIcon from '../assets/logo/avmainlogo.png';
import {
  BOTTOMBAR_DESKTOP_VISIBLE,
  SIDEBAR_DESKTOP_ANCHOR,
  SIDEBAR_MOBILE_ANCHOR,
  SIDEBAR_WIDTH,
  TOPBAR_DESKTOP_HEIGHT,
  TOPBAR_MOBILE_HEIGHT,
} from './config';
import TopBar from './TopBar/TopBar';
import SideBar from './SideBar/SideBar';
import BottomBar from './BottomBar';
import { BOTTOMBAR_ITEMS } from '../utils/constants';
import { useAppStore } from '../store';

// TODO: change to your app name or other word
const TITLE_PRIVATE = 'ARTISTVERIFIED'; // Title for pages after authentication

/**
 * SideBar navigation items with links
 */
const SIDEBAR_ITEMS = [
  {
    title: 'Welcome',
    path: '/welcome',
    icon: 'home',
  },
  {
    title: 'Tools',
    path: '/tools',
    icon: 'tools',
  },
  {
    title: 'About',
    path: '/about',
    icon: 'info',
  },
  // {
  //   title: 'Dev Tools',
  //   path: '/dev',
  //   icon: 'settings',
  // },
];

/**
 * Renders "Private Layout" composition
 * @component PrivateLayout
 */
const PrivateLayout = ({ children }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const onMobile = useOnMobile();
  const [state, dispatch] = useAppStore();

  // Set spotify token
  // Variant 1 - Sidebar is static on desktop and is a drawer on mobile
  const sidebarOpen = onMobile ? sideBarVisible : true;
  const sidebarVariant = onMobile ? 'temporary' : 'persistent';
  const topBarVisible = state.showTopBarPageList?.includes(location.pathname) ? true : false;
  const profileIconVisible = state.hideProfileIcon?.includes(location.pathname) ? false : true;
  const bottomBarVisible =
    (onMobile && !state.hideBottomBarPageList?.includes(location.pathname)) ||
    BOTTOMBAR_DESKTOP_VISIBLE;
  const fullCoverage = location.pathname.includes('/artist/') ? true : false;
  // Variant 2 - Sidebar is drawer on mobile and desktop
  // const sidebarOpen = sideBarVisible;
  // const sidebarVariant = 'temporary';

  const title = TITLE_PRIVATE;
  document.title = title; // Also Update Tab Title

  const onLogoClick = useCallback(() => {
    // Navigate to first SideBar's item or to '/' when clicking on Logo/Menu icon when SideBar is already visible
    navigation(SIDEBAR_ITEMS?.[0]?.path || '/');
  }, [navigation]);

  const onSideBarOpen = () => {
    if (!sideBarVisible) setSideBarVisible(true); // Don't re-render Layout when SideBar is already open
  };

  const onSideBarClose = () => {
    if (sideBarVisible) setSideBarVisible(false); // Don't re-render Layout when SideBar is already closed
  };

  return (
    <Stack
      direction="column"
      sx={{
        minHeight: '100vh', // Full screen height
        paddingTop: topBarVisible ? (onMobile ? TOPBAR_MOBILE_HEIGHT : TOPBAR_DESKTOP_HEIGHT) : 0,
        paddingLeft: sidebarOpen && SIDEBAR_DESKTOP_ANCHOR.includes('left') ? SIDEBAR_WIDTH : 0,
        paddingRight: sidebarOpen && SIDEBAR_DESKTOP_ANCHOR.includes('right') ? SIDEBAR_WIDTH : 0,
      }}
    >
      {topBarVisible && (
        <Stack component="header">
          <TopBar
            startNode={<img src={LogoIcon} alt="logo" width={'25'} height={'25'} />}
            endNode={
              profileIconVisible ? (
                <img
                  src={state.currentUser?.fan_picture_link ?? UserIcon}
                  style={{ borderRadius: '16px', marginLeft: 'auto' }}
                  alt="logo"
                  onClick={() => navigation('/profile')}
                  width={'48'}
                  height={'48'}
                />
              ) : null
            }
            title={'ARTISTVERIFIED'}
            toolbarSx={
              profileIconVisible
                ? {}
                : { diplay: 'flex', justifyContent: 'center', alignItems: 'center' }
            }
          />
        </Stack>
      )}
      <Stack
        component="main"
        sx={{
          flexGrow: 1, // Takes all possible space
          paddingLeft: fullCoverage ? 0 : 1,
          paddingRight: fullCoverage ? 0 : 1,
          paddingTop: fullCoverage ? 0 : 1,
          marginTop: topBarVisible ? 2 : 0,
        }}
      >
        <ErrorBoundary name="Content">{children}</ErrorBoundary>
      </Stack>
      <Stack component="footer" paddingTop={4}>
        <BottomBar items={BOTTOMBAR_ITEMS} />
      </Stack>
    </Stack>
  );
};

export default PrivateLayout;
